<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateStart"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateEnd"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="indigo" @click="Search">
          <v-icon dark>fa fa-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="green" @click="Prints">
          <v-icon dark>fa fa-print</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="hd"
          :items="ds"
          :items-per-page="-1"
          class="elevation-1"
        >
          <template v-slot:body.append>
            <tr>
              <td colspan="2" class="text-right">รวม</td>
              <td class="text-right">{{ sumTotal() }}</td>
              <td class="text-right"></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dateEnd: "",
      dateStart: "",
      hd: [
        {
          text: "วันที่ล่าสุด",
          align: "left",
          sortable: true,
          value: "RecieveDate",
          width: 100,
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: true,
          value: "UserName",
          width: 80,
        },
        {
          text: "คืน",
          align: "right",
          sortable: true,
          value: "RecieveTotal",
          width: 80,
        },
        {
          text: "รายละเอียด",
          align: "right",
          sortable: true,
          value: "RecieveRemark",
          width: 80,
        },
      ],
      ds: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select(this.dateStart, this.dateEnd);
    });
  },
  methods: {
    Search() {
      this.Select(this.dateStart, this.dateEnd);
    },
    Select(start, end) {
      axios
        .get(`/api/v1/select/Select/Recieve?DateStart=${start}&DateEnd=${end}`)
        .then((res) => {
          if (res.status == 200) {
            this.ds = res.data;
          }
        });
    },
    sumTotal() {
      return this.ds.reduce((total, num) => total+ num?.RecieveTotal,0);
    },
    Prints() {
      if (this.ds.length <= 0) return;
      axios
        .post("/api/v1/Report/pdf", {
          hdtext: "รายงานคืนเงิน",
          hd: this.hd,
          ite: this.ds,
          fot: [
            { text: "รวม", cols: 3 },
            { text: this.sumTotal(), cols: 1 },
            { text: "", cols: 1 },
          ],
        })
        .then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
  },
};
</script>
<style></style>
